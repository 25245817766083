/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef, MutableRefObject, RefObject, useEffect, useRef, useState} from 'react'
import {Button, Descriptions, Form, Input, Modal, Select, Table, Tag, Typography} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import {IPatchOrder, patchOrder} from '../../../../../api/orders'
import {CheckOutlined, CloseOutlined, FileOutlined} from '@ant-design/icons'
import {getPriceGroups, IPriceGroup} from '../../../../../api/priceGroups'
import dayjs from 'dayjs'
import {getFileCheck} from '../../../../../api/files'
import downloadFile from '../../../../../libs/downloadFile'
import styled from 'styled-components'
import {useReactToPrint} from 'react-to-print'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IPatchOrder>
  onAction: (type: ActionType, record: Partial<IPatchOrder>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

function Print({
  record: {data: record, coupons},
  printRef,
  handleClosePrint
}: {
  record: any
  printRef: React.RefObject<HTMLDivElement>
  handleClosePrint: () => void
}) {
  const [impressionFileNames, setImpressionFileNames] = useState<Object>([])
  const [addFileNames, setAddFileNames] = useState<Object>([])
  const [priceGroups, setPriceGroups] = useState<IPriceGroup[]>([])

  async function importFileNames() {
    const impressionFileNames: Object = {}
    const addFileNames: Object = {}

    for (const orderItem of record) {
      for (const {url} of orderItem?.product?.iteOptionInfo?.earImpressionFiles) {
        const slashSplitedPath = url.split('/')
        const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
        impressionFileNames[url] = fileName
      }

      for (const {url} of orderItem?.product?.iteOptionInfo?.addFiles) {
        const slashSplitedPath = url.split('/')
        const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
        addFileNames[url] = fileName
      }
    }

    setImpressionFileNames(impressionFileNames)
    setAddFileNames(addFileNames)
  }

  async function importPriceGroups() {
    const {data} = await getPriceGroups()
    setPriceGroups(data)
  }

  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current
    },
    documentTitle: record[0].orderId + ' 주문상세내역',
    pageStyle: `
      @page {
        size: 297mm 210mm ;
        margin: auto;
      }

      @media print {
        .page {
          margin: 0;
          border: initial;
          border-radius: initial;
          width: initial;
          min-height: initial;
          box-shadow: initial;
          background: initial;
          page-break-after: always;
        }
        
        @page { size: A4 }
        * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        }
      }
    `
  })

  useEffect(() => {
    importFileNames()
    importPriceGroups()
  }, [])

  return (
    <>
      <section style={{maxHeight: '60vh', overflowY: 'auto', border: '1px solid grey', borderBottom: 'none'}}>
        <section className="page" ref={printRef}>
          <PrintFrame>
            <table>
              <tr>
                <td
                  height={38}
                  data-t="s"
                  data-v="주 문 상 세 내 역"
                  id="sjs-A3"
                  style={{fontSize: 24, fontWeight: 'bold', textAlign: 'center', borderBottom: '1px solid grey'}}
                >
                  주문상세내역
                </td>
              </tr>
              <tr>
                <td height={10} />
              </tr>
            </table>

            {record.map((order) => (
              <section style={{padding: 20, borderBottom: '1px solid grey'}}>
                <table>
                  <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A4">
                      주문번호
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-C4">
                      {order.orderId}
                    </PrintText>
                    <PrintTitle data-t="s" id="sjs-F4">
                      주문상품번호
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-H4">
                      {order.orderProductId}
                    </PrintText>
                  </tr>
                </table>

                <table>
                  <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      구매자명
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-C6">
                      {order.buyerName}
                    </PrintText>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      고객명
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-C6">
                      {order.product.productInfo.weakName || order.product.productInfo.iteName || ''}
                    </PrintText>
                  </tr>
                  <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      상품명
                    </PrintTitle>
                    <PrintText colSpan={3} height={32} data-t="s" id="sjs-C6">
                      {order.product.productInfo.name}
                    </PrintText>
                  </tr>
                  <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      수량
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-C6">
                      {order.quantity}
                    </PrintText>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      보장구 바코드 신청 여부
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-C6">
                      {order.product.productInfo.isBarcode ? 'O' : 'X'}
                    </PrintText>
                  </tr>
                </table>

                {Object.keys(order.product.iteOptionInfo).length ? (
                  <table>
                    <tr>
                      <td width={420}>
                        <table style={{height: '100%'}}>
                          <tr>
                            <td
                              height={38}
                              colSpan={4}
                              data-t="s"
                              data-v="주 문 상 세 내 역"
                              id="sjs-A3"
                              style={{fontSize: 18, fontWeight: 'bold'}}
                            >
                              왼쪽
                            </td>
                          </tr>
                        </table>
                        <table>
                          <tr>
                            <PrintTitle width={80} colSpan={1} height={32} data-t="s" id="sjs-A4">
                              청력도
                            </PrintTitle>
                            <PrintText colSpan={3} height={32} data-t="s" id="sjs-C4">
                              <table>
                                <tr>
                                  {order.product.iteOptionInfo.leftInfo?.productAudiograms.map(({name}) => (
                                    <td>{name}Hz</td>
                                  )) || '-'}
                                </tr>
                                <tr>
                                  {order.product.iteOptionInfo.leftInfo?.productAudiograms.map(({value}) => (
                                    <td>{value}</td>
                                  )) || '-'}
                                </tr>
                              </table>
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              쉘 타입
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productShellTypes?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              출력
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productOutputs?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              쉘 색상
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productShellColors?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              플레이트
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productPlates?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              벤트
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productBents?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              벤트 스타일
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productBentStyles?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              카날 길이
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productCanalLengths?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              귀지 방지 옵션
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productEarwaxOptions?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              손잡이
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productHandles?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              쉘 사이즈
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productShellSizes?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              코팅
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productCoatings?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              프로그램 버튼
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.programButtons?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              볼륨 조절기
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.volumeControllers?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              무선
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.leftInfo?.productWireless?.name || '-'}
                            </PrintText>
                          </tr>
                        </table>
                      </td>
                      <td />
                      <td width={420}>
                        <table style={{height: '100%'}}>
                          <tr>
                            <td
                              height={38}
                              colSpan={4}
                              data-t="s"
                              data-v="주 문 상 세 내 역"
                              id="sjs-A3"
                              style={{fontSize: 18, fontWeight: 'bold'}}
                            >
                              오른쪽
                            </td>
                          </tr>
                        </table>
                        <table>
                          <tr>
                            <PrintTitle width={80} colSpan={1} height={32} data-t="s" id="sjs-A4">
                              청력도
                            </PrintTitle>
                            <PrintText colSpan={3} height={32} data-t="s" id="sjs-C4">
                              {
                                <table>
                                  <tr>
                                    {order.product.iteOptionInfo.rightInfo?.productAudiograms.map(({name}) => (
                                      <td>{name}Hz</td>
                                    )) || '-'}
                                  </tr>
                                  <tr>
                                    {order.product.iteOptionInfo.rightInfo?.productAudiograms.map(({value}) => (
                                      <td>{value}</td>
                                    )) || '-'}
                                  </tr>
                                </table>
                              }
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              쉘 타입
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productShellTypes?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              출력
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productOutputs?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              쉘 색상
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productShellColors?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              플레이트
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productPlates?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              벤트
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productBents?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              벤트 스타일
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productBentStyles?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              카날 길이
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productCanalLengths?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              귀지 방지 옵션
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productEarwaxOptions?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              손잡이
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productHandles?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              쉘 사이즈
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productShellSizes?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              코팅
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productCoatings?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              프로그램 버튼
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.programButtons?.name || '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              볼륨 조절기
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.volumeControllers?.name || '-'}
                            </PrintText>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              무선
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo.rightInfo?.productWireless?.name || '-'}
                            </PrintText>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <table>
                          <tr>
                            <td
                              height={38}
                              colSpan={4}
                              data-t="s"
                              data-v="주 문 상 세 내 역"
                              id="sjs-A3"
                              style={{fontSize: 18, fontWeight: 'bold'}}
                            >
                              귓본파일 & 제작참고사진 & 제작요청사항
                            </td>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              귓본파일
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo?.earImpressionFiles &&
                              order.product.iteOptionInfo?.earImpressionFiles.length
                                ? order.product.iteOptionInfo?.earImpressionFiles.map(({url}) => (
                                    <>
                                      <FileOutlined />
                                      {impressionFileNames[url] || url.split('/')[url.split('/').length - 1]}
                                      <br />
                                    </>
                                  ))
                                : '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              제작참고사진
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo?.addFiles && order.product.iteOptionInfo?.addFiles.length
                                ? order.product.iteOptionInfo?.addFiles.map(({url}) => (
                                    <>
                                      <FileOutlined />
                                      {addFileNames[url] || url.split('/')[url.split('/').length - 1]}
                                      <br />
                                    </>
                                  ))
                                : '-'}
                            </PrintText>
                          </tr>
                          <tr>
                            <PrintTitle height={32} data-t="s" id="sjs-A4">
                              제작요청사항
                            </PrintTitle>
                            <PrintText height={32} data-t="s" id="sjs-C4">
                              {order.product.iteOptionInfo?.memo || '-'}
                            </PrintText>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                ) : (
                  <table>
                    <tr>
                      <td
                        height={38}
                        colSpan={4}
                        data-t="s"
                        data-v="주 문 상 세 내 역"
                        id="sjs-A3"
                        style={{fontSize: 18, fontWeight: 'bold'}}
                      >
                        옵션
                      </td>
                    </tr>
                    <tr>
                      <PrintTitle height={32} data-t="s" id="sjs-A4">
                        컬러
                      </PrintTitle>
                      <PrintText height={32} data-t="s" id="sjs-C4">
                        {order.product.productInfo?.color !== 'undefined' ? order.product.productInfo?.color : '-'}
                      </PrintText>
                    </tr>
                    <tr>
                      <PrintTitle height={32} data-t="s" id="sjs-A4">
                        후크
                      </PrintTitle>
                      <PrintText height={32} data-t="s" id="sjs-C4">
                        {order.product.basicOptionInfo?.hooks && order.product.basicOptionInfo?.hooks.length
                          ? order.product.basicOptionInfo?.hooks.map(({name}) => name).join(', ')
                          : '-'}
                      </PrintText>
                    </tr>
                    <tr>
                      <PrintTitle height={32} data-t="s" id="sjs-A4">
                        충전기
                      </PrintTitle>
                      <PrintText height={32} data-t="s" id="sjs-C4">
                        {order.product.basicOptionInfo?.charges && order.product.basicOptionInfo?.charges.length
                          ? order.product.basicOptionInfo?.charges.map(({name}) => name).join(', ')
                          : '-'}
                      </PrintText>
                    </tr>
                    <tr>
                      <PrintTitle height={32} data-t="s" id="sjs-A4">
                        리시버
                      </PrintTitle>
                      <PrintText height={32} data-t="s" id="sjs-C4">
                        {order.product.basicOptionInfo?.receivers.length &&
                        order.product.basicOptionInfo?.receivers.filter((receiverItem) => receiverItem).length
                          ? order.product.basicOptionInfo?.receivers[0]?.product
                            ? order.product.basicOptionInfo?.receivers
                                .filter((receiver) => receiver?.product.productReceiverDirection)
                                .map(
                                  (receiver) =>
                                    `[${receiver?.product.productReceiverDirection?.name} / ${receiver?.product.productReceiverLength?.name} / ${receiver?.product.productReceiverPower?.name} (${receiver?.product.quantity}EA)]`
                                )
                                .join(', ')
                            : order.product.basicOptionInfo?.receivers
                                .map(
                                  (receiver) =>
                                    `[${receiver?.product.productReceiverDirection?.name} / ${receiver?.product.productReceiverLength?.name} / ${receiver?.product.productReceiverPower?.name} (${receiver?.product.quantity}EA)]`
                                )
                                .join(', ')
                          : '-'}
                      </PrintText>
                    </tr>
                  </table>
                )}

                <table>
                  {/* <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      주문상태
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-C6">
                      {order.orderStatus}
                    </PrintText>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      기본적용 가격라인
                    </PrintTitle>
                    <PrintText height={32} data-t="s" id="sjs-C6">
                      {priceGroups.find(({id}) => order.vendor.priceGroupId === id)?.name}
                    </PrintText>
                  </tr>
                  <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      가격
                    </PrintTitle>
                    <PrintText colSpan={5} height={32} data-t="s" id="sjs-C6">
                      {order.amount.toLocaleString()}
                    </PrintText>
                  </tr> */}
                  <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      적용 된 쿠폰명
                    </PrintTitle>
                    <PrintText colSpan={5} height={32} data-t="s" id="sjs-C6">
                      {order.product.couponInfo.length
                        ? order.couponInfo
                            .reduce((accParse, curParse) => {
                              const acc = JSON.stringify(accParse)
                              const cur = JSON.stringify(curParse)
                              if (acc.includes(cur)) {
                                return accParse
                              } else {
                                return [...accParse, curParse]
                              }
                            }, [])
                            .map(({name, type}) => (
                              <Tag style={{padding: 5}} color={process.env.REACT_APP_THEME_COLOR}>
                                <Tag color="white" style={{color: process.env.REACT_APP_THEME_COLOR}}>
                                  {type === 'price' ? '가격할인' : '번들/특별'}
                                </Tag>
                                {name}
                              </Tag>
                            ))
                        : '-'}
                    </PrintText>
                  </tr>
                  {/* <tr>
                    <PrintTitle height={32} data-t="s" id="sjs-A6">
                      변경 가격라인 / 할인된 가격
                    </PrintTitle>
                    <PrintText colSpan={5} height={32} data-t="s" id="sjs-C6">
                      {order.product.couponInfo.length
                        ? order.product.couponInfo
                            .map(
                              ({id, name, type}) =>
                                `${name} : ${
                                  coupons[0].map(({coupon}) => coupon).find(({id: couponItemId}) => id === couponItemId)
                                    ?.discounts[0]?.applyPriceGroup?.name ||
                                  coupons[0].map(({coupon}) => coupon).find(({id: couponItemId}) => id === couponItemId)
                                    ?.discounts[0]?.value
                                }`
                            )
                            .join(',')
                        : '-'}
                    </PrintText>
                  </tr> */}
                </table>
              </section>
            ))}
          </PrintFrame>
        </section>
      </section>
      <section style={{display: 'flex', justifyContent: 'center', gap: '1.5rem', marginTop: '3.2rem'}}>
        <Button onClick={handleClosePrint}>확인</Button>
        <Button type="primary" onClick={handlePrint}>
          인쇄
        </Button>
      </section>
    </>
  )
}

function Show({record: {data: record, coupons}}: {record: any}) {
  const [priceGroups, setPriceGroups] = useState<IPriceGroup[]>([])
  const [impressionFileNames, setImpressionFileNames] = useState<Object>([])
  const [addFileNames, setAddFileNames] = useState<Object>([])

  async function importFileNames() {
    const impressionFileNames: Object = {}
    const addFileNames: Object = {}

    for (const orderItem of record) {
      for (const {url} of orderItem?.product?.iteOptionInfo?.earImpressionFiles) {
        const slashSplitedPath = url.split('/')
        const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
        impressionFileNames[url] = fileName
      }

      for (const {url} of orderItem?.product?.iteOptionInfo?.addFiles) {
        const slashSplitedPath = url.split('/')
        const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
        addFileNames[url] = fileName
      }
    }

    setImpressionFileNames(impressionFileNames)
    setAddFileNames(addFileNames)
  }

  useEffect(() => {
    importFileNames()
  }, [])

  const columns = [
    {
      title: '주문상품 번호',
      dataIndex: 'orderProductId'
    },
    {
      title: '상품명',
      dataIndex: ['product', 'productInfo', 'name']
    },
    {
      title: '보장구 바코드 신청 여부',
      dataIndex: ['product', 'productInfo', 'isBarcode'],
      render: (isBarcode) => (isBarcode ? <CheckOutlined /> : <CloseOutlined />)
    },
    {
      title: '상품 옵션',
      dataIndex: ['product', 'productInfo', 'orderType'],
      render: (orderType, {product: {iteOptionInfo, productInfo, basicOptionInfo}, addText}) =>
        orderType === 'ITE' ? (
          <>
            {iteOptionInfo?.leftInfo && (
              <Descriptions title="왼쪽" bordered={true} column={2}>
                <Descriptions.Item label="쉘 타입">{iteOptionInfo?.leftInfo.productShellTypes?.name}</Descriptions.Item>
                <Descriptions.Item label="쉘 색상">
                  {iteOptionInfo?.leftInfo.productShellColors?.name}
                </Descriptions.Item>
                <Descriptions.Item label="쉘 사이즈">
                  {iteOptionInfo?.leftInfo.productShellSizes?.name}
                </Descriptions.Item>
                <Descriptions.Item label="플레이트">{iteOptionInfo?.leftInfo.productPlates?.name}</Descriptions.Item>
                <Descriptions.Item label="출력">{iteOptionInfo?.leftInfo.productOutputs?.name}</Descriptions.Item>
                <Descriptions.Item label="벤트">{iteOptionInfo?.leftInfo.productBents?.name}</Descriptions.Item>
                <Descriptions.Item label="벤트 스타일">
                  {iteOptionInfo?.leftInfo.productBentStyles?.name}
                </Descriptions.Item>
                <Descriptions.Item label="손잡이">{iteOptionInfo?.leftInfo.productHandles?.name}</Descriptions.Item>
                <Descriptions.Item label="귀지 방지 옵션">
                  {iteOptionInfo?.leftInfo.productEarwaxOptions?.name}
                </Descriptions.Item>
                <Descriptions.Item label="코팅">{iteOptionInfo?.leftInfo.productCoatings?.name}</Descriptions.Item>
                <Descriptions.Item label="카날 길이">
                  {iteOptionInfo?.leftInfo.productCanalLengths?.name}
                </Descriptions.Item>
                <Descriptions.Item label="무선">{iteOptionInfo?.leftInfo.productWireless?.name}</Descriptions.Item>
                <Descriptions.Item label="청력도">
                  {iteOptionInfo?.leftInfo.productAudiograms.map(({name, value}) => <Tag>{`${name}: ${value}`}</Tag>)}
                </Descriptions.Item>
                <Descriptions.Item label="볼륨 조절기">
                  {iteOptionInfo?.leftInfo.volumeControllers?.name}
                </Descriptions.Item>
                <Descriptions.Item label="프로그램 버튼">
                  {iteOptionInfo?.leftInfo.programButtons?.name}
                </Descriptions.Item>
              </Descriptions>
            )}
            <div style={{height: 30}} />
            {iteOptionInfo?.rightInfo && (
              <Descriptions title="오른쪽" bordered={true} column={2}>
                <Descriptions.Item label="쉘 타입">
                  {iteOptionInfo?.rightInfo.productShellTypes?.name}
                </Descriptions.Item>
                <Descriptions.Item label="쉘 색상">
                  {iteOptionInfo?.rightInfo.productShellColors?.name}
                </Descriptions.Item>
                <Descriptions.Item label="쉘 사이즈">
                  {iteOptionInfo?.rightInfo.productShellSizes?.name}
                </Descriptions.Item>
                <Descriptions.Item label="플레이트">{iteOptionInfo?.rightInfo.productPlates?.name}</Descriptions.Item>
                <Descriptions.Item label="출력">{iteOptionInfo?.rightInfo.productOutputs?.name}</Descriptions.Item>
                <Descriptions.Item label="벤트">{iteOptionInfo?.rightInfo.productBents?.name}</Descriptions.Item>
                <Descriptions.Item label="벤트 스타일">
                  {iteOptionInfo?.rightInfo.productBentStyles?.name}
                </Descriptions.Item>
                <Descriptions.Item label="손잡이">{iteOptionInfo?.rightInfo.productHandles?.name}</Descriptions.Item>
                <Descriptions.Item label="귀지 방지 옵션">
                  {iteOptionInfo?.rightInfo.productEarwaxOptions?.name}
                </Descriptions.Item>
                <Descriptions.Item label="코팅">{iteOptionInfo?.rightInfo.productCoatings?.name}</Descriptions.Item>
                <Descriptions.Item label="카날 길이">
                  {iteOptionInfo?.rightInfo.productCanalLengths?.name}
                </Descriptions.Item>
                <Descriptions.Item label="무선">{iteOptionInfo?.rightInfo.productWireless?.name}</Descriptions.Item>
                <Descriptions.Item label="청력도">
                  {iteOptionInfo?.rightInfo.productAudiograms.map(({name, value}) => <Tag>{`${name}: ${value}`}</Tag>)}
                </Descriptions.Item>
                <Descriptions.Item label="볼륨 조절기">
                  {iteOptionInfo?.rightInfo.volumeControllers?.name}
                </Descriptions.Item>
                <Descriptions.Item label="프로그램 버튼">
                  {iteOptionInfo?.rightInfo.programButtons?.name}
                </Descriptions.Item>
              </Descriptions>
            )}
            <Descriptions column={2} title="귓본파일 & 제작참고사진 & 제작요청사항" bordered={true}>
              <Descriptions.Item label="귓본파일">
                {iteOptionInfo?.earImpressionFiles
                  ? iteOptionInfo?.earImpressionFiles.map(({url}) => (
                      <>
                        <a
                          onClick={(e) => {
                            e.preventDefault()
                            downloadFile(url, impressionFileNames[url] || url.split('/')[url.split('/').length - 1])
                          }}
                        >
                          <FileOutlined />
                          {impressionFileNames[url] || url.split('/')[url.split('/').length - 1]}
                        </a>
                        <br />
                      </>
                    ))
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="제작참고사진">
                {iteOptionInfo?.addFiles
                  ? iteOptionInfo?.addFiles.map(({url}) => (
                      <>
                        <a
                          onClick={(e) => {
                            e.preventDefault()
                            downloadFile(url, addFileNames[url] || url.split('/')[url.split('/').length - 1])
                          }}
                        >
                          <FileOutlined />
                          {addFileNames[url] || url.split('/')[url.split('/').length - 1]}
                        </a>
                        <br />
                      </>
                    ))
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="제작요청사항">{iteOptionInfo?.memo}</Descriptions.Item>
            </Descriptions>
          </>
        ) : (
          <Descriptions bordered={true} column={2}>
            {!!productInfo?.color && <Descriptions.Item label="컬러">{productInfo?.color}</Descriptions.Item>}
            {!!basicOptionInfo?.hooks.length && (
              <Descriptions.Item label="후크">
                {basicOptionInfo?.hooks.map(({name}) => <Tag>{name}</Tag>)}
              </Descriptions.Item>
            )}
            {!!basicOptionInfo?.charges.length && (
              <Descriptions.Item label="충전기">
                {basicOptionInfo?.charges.map((charge) => <Tag>{charge?.name}</Tag>)}
              </Descriptions.Item>
            )}
            {!!basicOptionInfo?.receivers?.length && !!basicOptionInfo?.receivers[0]?.product && (
              <Descriptions.Item label="리시버">
                {basicOptionInfo?.receivers
                  .filter((receiver) => receiver?.product.productReceiverDirection)
                  .map((receiver) => (
                    <Tag>{`${receiver?.product.productReceiverDirection?.name} / ${receiver?.product.productReceiverLength?.name} / ${receiver?.product.productReceiverPower?.name} (${receiver.product.quantity}EA)`}</Tag>
                  ))}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="돔">
              {basicOptionInfo?.acc?.map(({name}) => <Tag>{name}</Tag>) || addText}
            </Descriptions.Item>
            {!!basicOptionInfo?.receivers?.length && !basicOptionInfo?.receivers[0]?.product && (
              <Descriptions.Item label="리시버">
                {basicOptionInfo?.receivers.map((receiver) =>
                  receiver ? (
                    <Tag>{`${receiver.productReceiverDirection?.name} / ${receiver.productReceiverLength?.name} / ${receiver.productReceiverPower?.name}`}</Tag>
                  ) : (
                    <></>
                  )
                )}
              </Descriptions.Item>
            )}
          </Descriptions>
        )
    },
    {
      title: '주문상태',
      dataIndex: 'orderStatus'
    },
    {
      title: '구매자명',
      dataIndex: 'buyerName'
    },
    {
      title: '고객명',
      dataIndex: ['product', 'productInfo'],
      render: ({weakName, iteName, name}) =>
        weakName ? (weakName !== name ? weakName : '-') : iteName ? (iteName !== name ? iteName : '-') : '-'
    },
    {
      title: '기본적용 가격라인',
      dataIndex: ['vendor', 'priceGroupId'],
      render: (priceGroupId) => priceGroups.find(({id}) => priceGroupId === id)?.name
    },
    {
      title: '가격',
      dataIndex: 'amount',
      render: (amount) => amount.toLocaleString()
    },
    {
      title: '적용된 쿠폰명',
      dataIndex: ['product', 'couponInfo'],
      render: (couponInfo) => {
        if (couponInfo.length) {
          const uniqueArr = couponInfo.reduce((accParse, curParse) => {
            const acc = JSON.stringify(accParse)
            const cur = JSON.stringify(curParse)
            if (acc.includes(cur)) {
              return accParse
            } else {
              return [...accParse, curParse]
            }
          }, [])
          return uniqueArr.map(({name, type}) => (
            <Tag style={{padding: 5}} color={process.env.REACT_APP_THEME_COLOR}>
              <Tag color="white" style={{color: process.env.REACT_APP_THEME_COLOR}}>
                {type === 'price' ? '가격할인' : '번들/특별'}
              </Tag>
              {name}
            </Tag>
          ))
        } else return '-'
      }
    },
    {
      title: '변경 가격라인 / 할인된 가격',
      dataIndex: ['product', 'couponInfo'],
      render: (couponInfo) =>
        couponInfo.length
          ? couponInfo
              .map(
                ({id, name, type}) =>
                  `${name} : ${
                    coupons[0].map(({coupon}) => coupon).find(({id: couponItemId}) => id === couponItemId)?.discounts[0]
                      ?.applyPriceGroup?.name ||
                    coupons[0].map(({coupon}) => coupon).find(({id: couponItemId}) => id === couponItemId)?.discounts[0]
                      ?.value
                  }`
              )
              .join(',')
          : '-'
    },
    {
      title: '수량',
      dataIndex: 'quantity'
    },
    {
      title: '송장 번호',
      dataIndex: 'trackingNumber'
    }
  ]

  async function importPriceGroups() {
    const {data} = await getPriceGroups()
    setPriceGroups(data)
  }

  useEffect(() => {
    importPriceGroups()
  }, [])

  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={2} bordered>
        <Descriptions.Item label="주문번호">{record[0].orderId}</Descriptions.Item>
        <Descriptions.Item label="배송 방식">{record[0].deliveryTypeName}</Descriptions.Item>
        <Descriptions.Item label="배송 일정">{record[0].deliveryTime || '-'}</Descriptions.Item>
        <Descriptions.Item label="접수 일자">
          {dayjs(record[0].createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
      <section style={{height: 30}} />
      <Typography.Title level={5}>주문상품 목록</Typography.Title>
      <Table
        columns={columns as any}
        dataSource={record}
        style={{overflow: 'auto', border: '1px solid #ebebeb'}}
        scroll={{x: 3000}}
      />
    </div>
  )
}

function AddOrEdit({
  form,
  record: {
    data: {0: record}
  },
  handleFinish
}) {
  useEffect(() => {
    form.setFieldsValue({status: record.orderStatus, id: record.orderId})
  }, [record])

  return (
    <Form {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
      <Form.Item name="id" label="ID" hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item label="상태" name="status">
        <Select>
          <Select.Option value="registered">registered</Select.Option>
          <Select.Option value="confirmed">confirmed</Select.Option>
          <Select.Option value="inspection">inspection</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="관리자 메모" name="adminMemo" rules={[{max: 2000, message: '최대 200자까지 입력 가능합니다.'}]}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="송장번호" name="trackingNumber">
        <Input />
      </Form.Item>
    </Form>
  )
}

const OrderModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '주문 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [isPrint, setIsPrint] = useState<boolean>(false)
  const printRef = useRef<HTMLDivElement>(null)

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchOrder) {
    setLoading(true)
    try {
      const {id, status, ...rest} = values
      id && (await patchOrder(id, {...rest, status}))
      ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
      onAction(type, values)
    } catch (e: any) {
      setLoading(false)
      if (e.response && e.response.data.message === 'already_in_use') {
        Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
      } else if (e.response) {
        Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
      throw e
    }
    setLoading(false)
    ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
    onAction(type, values)
  }

  function handleOpenPrint() {
    setIsPrint(true)
  }

  function handleClosePrint() {
    setIsPrint(false)
  }

  return (
    <BasicTableModal
      footer={[
        <Button key="print" onClick={handleOpenPrint}>
          인쇄
        </Button>
      ]}
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1200}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'edit')
          return <AddOrEdit form={form} record={record} handleFinish={(values) => handleFinish(type, values)} />
        return (
          <Form form={form}>
            <Show record={record} />
            <Modal width={1000} title="주문 상세 인쇄" footer={false} open={isPrint} onCancel={handleClosePrint}>
              <Print record={record} printRef={printRef} handleClosePrint={handleClosePrint} />
            </Modal>
          </Form>
        )
      }}
    />
  )
})

const PrintFrame = styled.section`
  table {
    width: 100%;
    margin-bottom: 10px;
  }
`

const PrintTitle = styled.td`
  width: 85px;
  font-size: 14px;
  color: #3d3d3d;
  font-weight: bold;
  border: 1px solid gray;
  text-align: center;
  word-break: keep-all;
`

const PrintText = styled.td`
  font-size: 14px;
  color: #3d3d3d;
  border: 1px solid gray;
  text-align: center;
  word-break: keep-all;

  table,
  tr,
  td {
    margin-top: 10px;
    border: 1px solid gray;
  }
`

export default OrderModal
