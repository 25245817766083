import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import PageContext from '../../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import OrderModal from './components/OrderModal'
import {getOrder, getOrders} from '../../../../api/orders'
import {Button, DatePicker, message} from 'antd'
import qs from 'query-string'
import {useNavigate} from 'react-router'
import moment from 'moment'
import dayjs from 'dayjs'
import XLSX from 'xlsx-js-style'

function Orders() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const query = qs.parse(location.search) as Record<string, string>
  const navigate = useNavigate()
  const [excelDownloadLoading, setExcelDownloadLoading] = useState(false)

  const columns = [
    {key: 'orderId', dataIndex: 'orderId', title: '주문번호'},
    {
      dataIndex: 'deliveryTypeName',
      title: '배송 방식'
    },
    {dataIndex: ['product', 'productInfo', 'name'], title: '주문상품명'},
    {
      dataIndex: ['product', 'productInfo', 'color'],
      title: '색상',
      width: 180,
      render: (
        color,
        {
          product: {
            productInfo: {orderType}
          }
        }
      ) => (orderType === 'ITE' ? <></> : color)
    },
    {dataIndex: ['product', 'productInfo', 'weakName'], title: '고객명', width: 80},
    {dataIndex: 'buyerName', title: '구매자명'},
    {dataIndex: 'amount', title: '금액'},
    {dataIndex: 'quantity', title: '수량'},
    {dataIndex: 'createdAt', title: '접수일자', render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}
  ]

  async function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) {
        const record = await getOrder(data.orderId)
        modalRef.current.showModal({type: 'show', record})
      } else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  function onSelectFilter(key: string | string[], value: string | string[]) {
    const search: any = {...query}

    if (Array.isArray(key))
      key.forEach((keyItem, index) => {
        if (!value[index]) delete search[keyItem]
        else search[keyItem] = value[index]
      })
    else if (typeof key === 'string') {
      if (!value || value === 'null') delete search[key]
      else search[key] = value
    }
    navigate({
      pathname: location.pathname,
      search: qs.stringify(search)
    })
  }

  async function handleExcelDownload() {
    const selectedOrders: any = []

    setExcelDownloadLoading(true)

    const confirmDownload = confirm(
      '선택 된 날짜 범위만큼의 주문건을 엑셀로 다운로드 하시겠습니까? 대량 데이터의 경우 시간이 소요될 수 있습니다.'
    )
    if (!confirmDownload) return

    let i = 0
    try {
      while (true) {
        const {data}: any = await getOrders({...query, start: 100 * i, perPage: 100})
        if (data.length === 0) break
        selectedOrders.push(...data)
        i++
      }
    } catch (e) {
      message.error('데이터를 불러오는 중 오류가 발생했습니다.')
      setExcelDownloadLoading(false)
      return
    }

    const workbook = XLSX.utils.book_new()
    const worksheetData = selectedOrders.map((order) => [
      order.orderId,
      order.deliveryTypeName,
      order.product.productInfo.name,
      order.product.productInfo.color,
      order.product.productInfo.grade,
      order.product.basicOptionInfo.charges?.map(({name}) => name).join(', ') || '-',
      order.product.basicOptionInfo.receivers?.map((receiver) => receiver?.product?.materialDescription).join(', ') ||
        '-',
      order.product.productInfo.weakName,
      order.buyerName,
      order.amount,
      order.quantity,
      order.product.basicOptionInfo.acc?.map((acc) => acc?.name).join(', ') || order.addText,
      dayjs(order.createdAt).format('YYYY-MM-DD HH:mm:ss')
    ])

    if (worksheetData[6] === ', ') worksheetData[6] = '-'

    const headers = [
      '주문번호',
      '배송 방식',
      '주문상품명',
      '색상',
      '등급',
      '충전기',
      '리시버',
      '고객명',
      '구매자명',
      '금액',
      '수량',
      '돔',
      '접수일자'
    ]

    worksheetData.unshift(headers)

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

    // 스타일링 추가
    worksheet['!cols'] = headers.map(() => ({wpx: 120})) // 열 너비 설정

    XLSX.utils.book_append_sheet(workbook, worksheet, '사용자 데이터')
    XLSX.writeFile(workbook, `Users_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`)

    setExcelDownloadLoading(false)
  }

  return (
    <>
      <BasicTable
        topButtons={[
          <>
            <DatePicker
              value={query.startDate ? (moment(query.startDate) as any) : null}
              onChange={(value) => onSelectFilter('startDate', value ? `${value.format('YYYY-MM-DD')}` : 'null')}
            />
            &nbsp;~&nbsp;
            <DatePicker
              value={query.endDate ? (moment(query.endDate) as any) : null}
              onChange={(value) =>
                onSelectFilter('endDate', value ? `${value.add(1, 'd').format('YYYY-MM-DD')}` : 'null')
              }
            />
            <Button
              disabled={!query.startDate && !query.endDate}
              onClick={handleExcelDownload}
              loading={excelDownloadLoading}
            >
              선택 된 범위 엑셀 다운로드
            </Button>
          </>
        ]}
        showSearch
        searchPlaceHolder="주문번호 검색"
        rowKey="id"
        columns={columns}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <OrderModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(Orders, getOrders)
