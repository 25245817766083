import React, {createContext, useReducer} from 'react'
import {PageContextProps} from './PageContext'

type Action<T> = {type: 'SET_VALUE'; key: string; value: any[]} | {type: 'INIT_VALUES'}

const defaultValue = {
  channels: [],
  colors: [],
  detailType1: [],
  detailType2: [],
  families: [],
  familyDetails: [],
  itemGroups: [],
  marketSegments: [],
  patientCategories: [],
  lines: [],
  salesPlatforms: [],
  segments: [],
  productReceiverDirections: [],
  productReceiverLengths: [],
  productReceiverPowers: [],
  productChargers: [],
  productOptionCharges: [],
  productOptionReceivers: [],
  productAccs: [],
  changeColors: [],
  brands: [],
  orderTypes: [],
  marketingPlatforms: [],
  salesProducts: [],
  salesProductDetails: [],
  finalValues: [],
  iteOptions: [],
  deliveryTypes: [],
  itemOptions: [],
  productGrades: []
}

export const ProductStateContext = createContext<PageContextProps<any>>(defaultValue)
export const ProductDispatchContext = createContext<React.Dispatch<Action<any>>>(() => null)

function reducer(state: any, action: Action<any>) {
  switch (action.type) {
    case 'SET_VALUE':
      return {...state, [action.key]: action.value}
    case 'INIT_VALUES':
      return defaultValue
    default:
      break
  }
}

function ProductContext({children}) {
  const [state, dispatch] = useReducer(reducer, defaultValue)

  return (
    <ProductStateContext.Provider value={state}>
      <ProductDispatchContext.Provider value={dispatch}>{children}</ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  )
}

export default ProductContext
