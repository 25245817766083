import React, {forwardRef, MutableRefObject, RefObject, useContext, useEffect, useState} from 'react'
import {
  Button,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Typography,
  Upload as AntdUpload,
  List
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {
  deleteProduct,
  getProduct,
  getProducts,
  IPatchProducts,
  IProduct,
  patchProduct,
  postProduct
} from '../../../../../api/products'
import {CheckOutlined, CloseOutlined, FileExcelOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import * as XLSX from 'xlsx'
import getColumnItem from '../../../../../components/getColumnItem'
import ConvertCamelCase from '../../../../../libs/convertCamelCase'
import {getProductChannels} from '../../../../../api/productChannels'
import {getProductColors} from '../../../../../api/productColors'
import {getProductDetailType1s} from '../../../../../api/productDetailType1'
import {getProductDetailType2s} from '../../../../../api/productDetailType2'
import {getFamilies} from '../../../../../api/families'
import {getFamilyDetails} from '../../../../../api/familyDetails'
import {getProductItemGroups} from '../../../../../api/productItemGroups'
import {getMarketSegments} from '../../../../../api/marketSegments'
import {getPatientCategories} from '../../../../../api/patientCategories'
import {getLines} from '../../../../../api/lines'
import {getSalesPlatforms} from '../../../../../api/salesPlatforms'
import {getSegments} from '../../../../../api/segments'
import {ProductDispatchContext, ProductStateContext} from '../../../../../contexts/ProductModalContext'
import {getProductBrands} from '../../../../../api/productBrands'
import {getProductOrderTypes} from '../../../../../api/productOrderTypes'
import {getMarketingPlatforms} from '../../../../../api/marketingPlatforms'
import {getSalesProducts} from '../../../../../api/salesProducts'
import Upload from '../../../../../components/Upload'
import {getFinalValues} from '../../../../../api/finalValues'
import {uploadImage} from '../../../../../libs/uploadImage'
import ImageView from '../../../../../components/ImageView'
import {getIteOption, getIteOptions, IteOption} from '../../../../../api/iteOption'
import {getDeliveryTypes} from '../../../../../api/deliveryTypes'
import {getPrices, IPrice} from '../../../../../api/prices'
import {postExcel} from '../../../../../api/excels'
import chunk from '../../../../../libs/chunk'
import {getSalesProductDetails} from '../../../../../api/salesProductDetails'
import {getProductGrades} from '../../../../../api/productGrades'
import {getProductOptionCharges, getProductOptionReceivers} from '../../../../../api/productOption'
import {getProductReceiverDirections} from '../../../../../api/productReceiverDirection'
import {getProductReceiverPowers} from '../../../../../api/productReceiverPower'
import {getProductReceiverLengths} from '../../../../../api/productReceiverLength'
import {getProductChargers} from '../../../../../api/productChargers'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

const defaultKeys: any = [
  {key: 'materialDescription', title: '이름', required: true},
  {key: 'material', title: '품목번호', required: false},
  {key: 'barcode', title: '바코드', required: false, type: 'string'},
  {key: 'salesNumber', title: '판매단위별 수', required: false, type: 'number'},
  {key: 'purchaseNumber', title: '구매단위별 수', required: false, type: 'number'},
  {key: 'deliveryType', title: '출하 유형', required: false},
  {key: 'currencyType', title: '최종 구매 통화', required: false},
  {key: 'brand', title: '브랜드', required: true},
  {key: 'orderType', title: '제품군', required: true},
  {key: 'itemGroup', title: '품목그룹', required: false},
  {key: 'marketingPlatform', title: '마케팅', required: false},
  {key: 'marketSegment', title: '마켓 구분', required: false},
  {key: 'salesProduct', title: '판매 제품', required: false},
  {key: 'salesProductDetail', title: '판매 제품 디테일', required: false},
  {key: 'channel', title: '채널', required: false},
  {key: 'color', title: '컬러', required: false},
  {key: 'detailType1', title: '디테일 유형 1', required: false},
  {key: 'detailType2', title: '디테일 유형 2', required: false},
  {key: 'family', title: '카테고리', required: false},
  {key: 'familyDetail', title: '카테고리 디테일', required: false},
  {key: 'patientCategory', title: '고객 상태', required: false},
  {key: 'line', title: '생산라인', required: false},
  {key: 'salesPlatform', title: '판매 플랫폼', required: false},
  {key: 'segment', title: '세그먼트', required: false},
  {key: 'productFinalValue', title: '최종 분류 값', required: false},
  {key: 'productOptionReceiver', title: '리시버', required: false},
  {key: 'productOptionCharge', title: '충전기', required: false},
  {key: 'productReceiverPower', title: '리시버 출력', required: false},
  {key: 'productReceiverLength', title: '리시버 길이', required: false},
  {key: 'productReceiverDirection', title: '리시버 방향', required: false},
  {key: 'productCharger', title: '충전기', required: false},
  {key: 'productAcc', title: 'ACC', required: false},
  {key: 'productGrade', title: '등급', required: false},
  {key: 'iteOption', title: 'ITE 옵션', required: false}
]

const priceColumn = [
  {key: 'id', dataIndex: 'id', title: 'ID'},
  {dataIndex: ['priceGroup', 'name'], title: '판매가 그룹명'},
  {dataIndex: 'price', title: '판매가'}
]

function Show({record}: {record: IProduct}) {
  const [data, setData] = useState<IProduct | null>(null)
  const [prices, setPrices] = useState<IPrice[]>([])
  const [selectedIteOption, setSelectedIteOption] = useState<IteOption | null>(null)

  async function handleGetProduct() {
    const data = await getProduct(record.id)
    setData(data)
  }

  async function handleGetPrices() {
    const {data} = await getPrices()
    setPrices(data.filter(({product}) => product?.id === record.id))
  }

  async function handleGetIteOption() {
    const iteOption = await getIteOption(data?.iteOptionId || 0)
    setSelectedIteOption(iteOption)
  }

  useEffect(() => {
    handleGetProduct()
    handleGetPrices()
  }, [])

  useEffect(() => {
    handleGetIteOption()
  }, [data])

  const tab1 = (
    <Descriptions column={1} bordered>
      <Descriptions.Item label="이름">{data?.materialDescription}</Descriptions.Item>
      <Descriptions.Item label="품목번호">{data?.material}</Descriptions.Item>
      <Descriptions.Item label="바코드">{data?.barcode}</Descriptions.Item>
      <Descriptions.Item label="수량">{data?.quantity}</Descriptions.Item>
      <Descriptions.Item label="판매 단위">{data?.quantityUnit}</Descriptions.Item>
      <Descriptions.Item label="안전재고 수량">{data?.safetyQuantity}</Descriptions.Item>
      <Descriptions.Item label="판매단위별 수">{data?.salesNumber}</Descriptions.Item>
      <Descriptions.Item label="구매단위별 수">{data?.purchaseNumber}</Descriptions.Item>
      <Descriptions.Item label="배송 방법">{data?.deliveryType}</Descriptions.Item>
      <Descriptions.Item label="최종 구매 통화">{data?.currencyType}</Descriptions.Item>
      <Descriptions.Item label="채널">{data?.channel?.name}</Descriptions.Item>
      <Descriptions.Item label="컬러">{data?.color?.name}</Descriptions.Item>
      <Descriptions.Item label="디테일 유형 1">{data?.detailType1?.name}</Descriptions.Item>
      <Descriptions.Item label="디테일 유형 2">{data?.detailType2?.name}</Descriptions.Item>
      <Descriptions.Item label="카테고리">{data?.family?.name}</Descriptions.Item>
      <Descriptions.Item label="카테고리 디테일">{data?.familyDetail?.name}</Descriptions.Item>
      <Descriptions.Item label="품목그룹">{data?.itemGroup?.name}</Descriptions.Item>
      <Descriptions.Item label="등급">{data?.marketSegment?.name}</Descriptions.Item>
      <Descriptions.Item label="고객 상태">{data?.patientCategory?.name}</Descriptions.Item>
      <Descriptions.Item label="라인">{data?.line?.name}</Descriptions.Item>
      <Descriptions.Item label="판매 플랫폼">{data?.salesPlatform?.name}</Descriptions.Item>
      <Descriptions.Item label="세그먼트">{data?.segment?.name}</Descriptions.Item>
      <Descriptions.Item label="등급">{data?.productGrade?.name}</Descriptions.Item>
      <Descriptions.Item label="배터리 타입">{data?.batteryType}</Descriptions.Item>
      <Descriptions.Item label="리시버">
        <Table
          columns={[
            {
              title: '리시버명',
              dataIndex: 'materialDescription'
            },
            {
              title: '길이',
              dataIndex: ['length', 'name']
            },
            {
              title: '출력',
              dataIndex: ['power', 'name']
            },
            {
              title: '방향',
              dataIndex: ['direction', 'name']
            }
          ]}
          dataSource={data?.orderType?.name.includes('Trial') ? [] : data?.option?.receiver}
        />
      </Descriptions.Item>
      <Descriptions.Item label="리시버 방향">{data?.productReceiverDirection?.name}</Descriptions.Item>
      <Descriptions.Item label="리시버 출력">{data?.productReceiverPower?.name}</Descriptions.Item>
      <Descriptions.Item label="리시버 길이">{data?.productReceiverLength?.name}</Descriptions.Item>
      <Descriptions.Item label="충전기 유형">{data?.productCharger?.name}</Descriptions.Item>
      <Descriptions.Item label="충전기">
        <List
          dataSource={data?.orderType?.name.includes('Trial') ? [] : data?.option?.charge}
          renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
        />
      </Descriptions.Item>
      <Descriptions.Item label="ACC">
        <List
          dataSource={data?.orderType?.name.includes('Trial') ? [] : data?.option?.acc}
          renderItem={(item: any) => <List.Item>{item.materialDescription}</List.Item>}
        />
      </Descriptions.Item>
      <Descriptions.Item label="후크">
        <List dataSource={data?.option?.hook} renderItem={(item: any) => <List.Item>{item.name}</List.Item>} />
      </Descriptions.Item>
      <Descriptions.Item label="브랜드">{data?.brand?.name}</Descriptions.Item>
      <Descriptions.Item label="제품군">{data?.orderType?.name}</Descriptions.Item>
      <Descriptions.Item label="마케팅">{data?.marketingPlatform?.name}</Descriptions.Item>
      <Descriptions.Item label="최종 분류 값">{data?.productFinalValue?.name}</Descriptions.Item>
      <Descriptions.Item label="ITE 옵션 값 (그룹)">{selectedIteOption?.name}</Descriptions.Item>
      <Descriptions.Item label="판매 제품">{data?.salesProduct?.name}</Descriptions.Item>
      <Descriptions.Item label="판매 제품 상세">{data?.salesProductDetail?.name}</Descriptions.Item>
      <Descriptions.Item label="판매여부">{data?.onSale ? <CheckOutlined /> : <CloseOutlined />}</Descriptions.Item>
      <Descriptions.Item label="상태">
        {data?.status === 'hidden' ? '숨김' : data?.status === 'discontinued' ? '판매중지' : '판매중'}
      </Descriptions.Item>
      <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
      <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
    </Descriptions>
  )

  const tab2 = (
    <Descriptions column={1} bordered>
      <Descriptions.Item label="대표 이미지">
        <ImageView src={data?.titleImage as string} />
      </Descriptions.Item>
      <Descriptions.Item label="요약 설명">{data?.summary}</Descriptions.Item>
      <Descriptions.Item label="상세 설명">{data?.description}</Descriptions.Item>
    </Descriptions>
  )

  const tab3 = <Table dataSource={prices} columns={priceColumn} />

  const tabs = [
    {
      label: '기본 정보',
      key: 'default',
      children: tab1
    },
    {
      label: '세부 정보',
      key: 'detail',
      children: tab2
    },
    {
      label: '판매가',
      key: 'price',
      children: tab3
    }
  ]

  return (
    <div style={{padding: '0 28px'}}>
      <Tabs type="card" items={tabs} />
    </div>
  )
}

function AddOrEdit({
  type,
  form,
  record,
  handleFinish,
  addType,
  setAddType,
  excelData,
  setExcelData,
  selectedKeys,
  setSelectedKeys,
  handleExcelReset,
  keys,
  setKeys
}) {
  const extDataState = useContext(ProductStateContext)
  const extDataDispatch = useContext(ProductDispatchContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (excelData[0]) {
      let keys: string[] = []
      excelData = excelData.map((excelDataItem) => {
        const itemKeys = Object.keys(excelDataItem)
        if (keys.length < itemKeys.length) {
          keys = [...keys, ...itemKeys]
        }
        return {...excelDataItem, tempId: `${excelDataItem.material}_${Math.random()}`}
      })
      setKeys(keys)
      setSelectedKeys(Object.keys(keys).map((defaultKey) => keys.find((key) => key === defaultKey)))
    }
  }, [excelData])

  const columns = defaultKeys.map(({title, required, type, enumVal}, index) =>
    getColumnItem({
      selectedKeys,
      setExcelData,
      setSelectedKeys,
      keys,
      index,
      title,
      required,
      type,
      enumVal
    })
  )

  useEffect(() => {
    async function getList(getApi, key, excelKey) {
      let listData: any = []
      let i = 0
      while (1) {
        const {data} = await getApi({start: i * 100, perPage: 100})
        listData = [...listData, ...data]
        if (!data.length) break
        else i++
      }
      const defKeyIdx = defaultKeys.findIndex(({key}) => key === excelKey)
      defaultKeys[defKeyIdx].enumVal = listData.map(({name, materialDescription}) =>
        materialDescription ? materialDescription : name
      )
      extDataDispatch({type: 'SET_VALUE', key, value: listData})
    }

    async function fetchData() {
      setLoading(true) // 모든 API 호출 전에 loading 시작
      try {
        await getList(getProductChargers, 'productChargers', 'productCharger')
        await getList(getProductChannels, 'channels', 'channel')
        await getList(getProductColors, 'colors', 'color')
        await getList(getProductDetailType1s, 'detailType1', 'detailType1')
        await getList(getProductDetailType2s, 'detailType2', 'detailType2')
        await getList(getFamilies, 'families', 'family')
        await getList(getFamilyDetails, 'familyDetails', 'familyDetail')
        await getList(getProductItemGroups, 'itemGroups', 'itemGroup')
        await getList(getMarketSegments, 'marketSegments', 'marketSegment')
        await getList(getPatientCategories, 'patientCategories', 'patientCategory')
        await getList(getSalesPlatforms, 'salesPlatforms', 'salesPlatform')
        await getList(getSegments, 'segments', 'segment')
        await getList(getProductOptionReceivers, 'productOptionReceivers', 'productOptionReceiver')
        await getList(getProductOptionCharges, 'productOptionCharges', 'productOptionCharge')
        await getList(getProductBrands, 'brands', 'brand')
        await getList(getProductOrderTypes, 'orderTypes', 'orderType')
        await getList(getMarketingPlatforms, 'marketingPlatforms', 'marketingPlatform')
        await getList(getSalesProducts, 'salesProducts', 'salesProduct')
        await getList(getSalesProductDetails, 'salesProductDetails', 'salesProductDetail')
        await getList(getFinalValues, 'finalValues', 'productFinalValue')
        await getList(getIteOptions, 'iteOptions', 'iteOption')
        await getList(getDeliveryTypes, 'deliveryTypes', 'deliveryType')
        await getList(getProductGrades, 'productGrades', 'productGrade')
        await getList(getProductReceiverDirections, 'productReceiverDirections', 'productReceiverDirection')
        await getList(getProductReceiverPowers, 'productReceiverPowers', 'productReceiverPower')
        await getList(getProductReceiverLengths, 'productReceiverLengths', 'productReceiverLength')
        await getList(getLines, 'lines', 'line')
        await getList((pagination) => getProducts({orderTypeId: 1, ...pagination}), 'productAccs', 'productAcc')
      } finally {
        setLoading(false) // 모든 API 호출 후 loading 종료
      }
    }

    if (form && record) {
      fetchData()
      type === 'edit' && handleGetProduct()
    }

    return () => {
      setAddType('default')
      extDataDispatch({type: 'INIT_VALUES'})
    }
  }, [form, record])

  async function handleGetProduct() {
    const data = await getProduct(record.id)
    form.setFieldsValue({
      ...data,
      channelId: data.channel?.id || null,
      colorId: data.color?.id || null,
      detailType1Id: data.detailType1?.id || null,
      detailType2Id: data.detailType2?.id || null,
      familyId: data.family?.id || null,
      familyDetailId: data.familyDetail?.id || null,
      itemGroupId: data.itemGroup?.id,
      marketSegmentId: data.marketSegment?.id || null,
      patientCategoryId: data.patientCategory?.id || null,
      lineId: data.line?.id || null,
      salesPlatformId: data.salesPlatform?.id || null,
      segmentId: data.segment?.id || null,
      receiverIds: Object.keys(extDataState.productOptionReceivers).length
        ? data.option?.receiver
            ?.filter(({id}) => extDataState.productOptionReceivers.find(({id: receiverId}) => receiverId === id))
            .map(({id}) => id) || []
        : data.option?.receiver?.map(({id}) => id),
      productReceiverDirectionId: data.productReceiverDirection?.id || null,
      productReceiverPowerId: data.productReceiverPower?.id || null,
      productReceiverLengthId: data.productReceiverLength?.id || null,
      productChargerId: data.productCharger?.id || null,
      accIds: data.option?.acc?.map(({id}) => id) || [],
      chargeIds: data.option?.charge?.map(({id}) => id) || [],
      hookIds: data.option?.hook?.map(({id}) => id) || [],
      brandId: data.brand?.id,
      orderTypeId: data.orderType?.id,
      marketingPlatformId: data.marketingPlatform?.id || null,
      salesProductId: data.salesProduct?.id || null,
      salesProductDetailId: data.salesProductDetail?.id || null,
      finalValueId: data.productFinalValue?.id || null,
      iteOptionId: data.iteOptionId || null,
      gradeId: data.productGrade?.id || null
    })
  }

  function handleUploadExcel({file}) {
    if (file.status !== 'uploading') {
      const reader = new FileReader()
      handleExcelReset()
      reader.onload = function () {
        const fileData = reader.result
        const wb = XLSX.read(fileData, {type: 'binary'})
        wb.SheetNames.forEach(function (sheetName) {
          let rowObj: any = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
          rowObj = rowObj.map((objItem) => {
            const newObj = {}
            Object.keys(objItem).forEach((key) => {
              newObj[ConvertCamelCase(key)] = objItem[key]
            })
            return newObj
          })
          setExcelData((prev) => [...prev, ...rowObj])
        })
      }

      reader.readAsBinaryString(file.originFileObj)
    }
  }

  const selectAddType = (
    <Radio.Group
      defaultValue={addType}
      onChange={(e) => {
        setAddType(e.target.value)
        handleExcelReset()
      }}
      style={{margin: '0 0 28px 0'}}
    >
      <Radio.Button value="default" defaultChecked>
        직접 입력
      </Radio.Button>
      <Radio.Button value="excel">엑셀 파일 업로드</Radio.Button>
    </Radio.Group>
  )

  const defaultAddType = (
    <>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item label="이름" name="materialDescription" rules={[{required: true, message: '이름을 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="대표 이미지" name="titleImage">
        <Upload title="titleImage" max={1} />
      </Form.Item>

      <Form.Item label="품목번호" name="material">
        <Input />
      </Form.Item>

      <Form.Item label="바코드" name="barcode">
        <Input />
      </Form.Item>

      <Form.Item label="수량" name="quantity">
        <InputNumber />
      </Form.Item>

      <Form.Item label="안전재고 수량" name="safetyQuantity">
        <InputNumber />
      </Form.Item>

      <Form.Item label="판매 단위" name="quantityUnit">
        <InputNumber />
      </Form.Item>

      <Form.Item label="판매단위별 수" name="salesNumber">
        <InputNumber />
      </Form.Item>

      <Form.Item label="구매단위별 수" name="purchaseNumber">
        <InputNumber />
      </Form.Item>

      <Form.Item label="배터리 타입" name="batteryType">
        <Input />
      </Form.Item>

      <Form.Item label="출하 유형" name="deliveryType">
        <Select loading={loading}>
          {extDataState.deliveryTypes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((deliveryType) => (
              <Select.Option key={deliveryType.name} value={deliveryType.name}>
                {deliveryType.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="최종 구매 통화" name="currencyType">
        <Input />
      </Form.Item>

      <Form.Item label="판매 여부" name="onSale" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="제품군" name="orderTypeId" rules={[{required: true, message: '제품군을 선택해주세요'}]}>
        <Select loading={loading}>
          {extDataState.orderTypes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((orderType) => (
              <Select.Option key={orderType.id} value={orderType.id}>
                {orderType.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="채널" name="channelId">
        <Select loading={loading}>
          {extDataState.channels
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((channel) => (
              <Select.Option key={channel.id} value={channel.id}>
                {channel.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="컬러" name="colorId">
        <Select loading={loading} allowClear>
          {extDataState.colors
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((color) => (
              <Select.Option key={color.id} value={color.id}>
                {color.name} ({color.code})
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="디테일 유형 1" name="detailType1Id">
        <Select loading={loading}>
          {extDataState.detailType1
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((detailType1Item) => (
              <Select.Option key={detailType1Item.id} value={detailType1Item.id}>
                {detailType1Item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="디테일 유형 2" name="detailType2Id">
        <Select>
          {extDataState.detailType2
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((detailType2Item) => (
              <Select.Option key={detailType2Item.id} value={detailType2Item.id}>
                {detailType2Item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="패밀리" name="familyId">
        <Select loading={loading} allowClear>
          {extDataState.families
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((family) => (
              <Select.Option key={family.id} value={family.id}>
                {family.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="패밀리 디테일" name="familyDetailId">
        <Select loading={loading} allowClear>
          {extDataState.familyDetails
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((familyDetail) => (
              <Select.Option key={familyDetail.id} value={familyDetail.id}>
                {familyDetail.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="품목그룹" name="itemGroupId">
        <Select loading={loading}>
          {extDataState.itemGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((itemGroup) => (
              <Select.Option key={itemGroup.id} value={itemGroup.id}>
                {itemGroup.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="마켓 세그먼트" name="marketSegmentId">
        <Select loading={loading} allowClear>
          {extDataState.marketSegments
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((marketSegment) => (
              <Select.Option key={marketSegment.id} value={marketSegment.id}>
                {marketSegment.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="고객 상태" name="patientCategoryId">
        <Select loading={loading}>
          {extDataState.patientCategories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((patientCategory) => (
              <Select.Option key={patientCategory.id} value={patientCategory.id}>
                {patientCategory.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="생산라인" name="lineId">
        <Select loading={loading} allowClear>
          {extDataState.lines
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((line) => (
              <Select.Option key={line.id} value={line.id}>
                {line.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="판매 플랫폼" name="salesPlatformId">
        <Select loading={loading} allowClear>
          {extDataState.salesPlatforms
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((salesPlatform) => (
              <Select.Option key={salesPlatform.id} value={salesPlatform.id}>
                {salesPlatform.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="세그먼트" name="segmentId">
        <Select loading={loading}>
          {extDataState.segments
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((segment) => (
              <Select.Option key={segment.id} value={segment.id}>
                {segment.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="등급 (Grade)" name="gradeId">
        <Select loading={loading} allowClear>
          {extDataState.productGrades
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((grade) => (
              <Select.Option key={grade.id} value={grade.id}>
                {grade.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="리시버" name="receiverIds">
        <Select loading={loading} mode="multiple">
          {extDataState.productOptionReceivers
            .sort((a, b) => a.materialDescription.localeCompare(b.name))
            .map((receiver) => (
              <Select.Option key={receiver.id} value={receiver.id}>
                {receiver.materialDescription}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="리시버 방향" name="productReceiverDirectionId">
        <Select loading={loading}>
          {extDataState.productReceiverDirections
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((direction) => (
              <Select.Option key={direction.id} value={direction.id}>
                {direction.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="리시버 출력" name="productReceiverPowerId">
        <Select loading={loading}>
          {extDataState.productReceiverPowers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((power) => (
              <Select.Option key={power.id} value={power.id}>
                {power.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="리시버 길이" name="productReceiverLengthId">
        <Select loading={loading}>
          {extDataState.productReceiverLengths
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((length) => (
              <Select.Option key={length.id} value={length.id}>
                {length.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="충전기 유형" name="productChargerId">
        <Select loading={loading}>
          {extDataState.productChargers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((length) => (
              <Select.Option key={length.id} value={length.id}>
                {length.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="충전기" name="chargeIds">
        <Select loading={loading} mode="multiple">
          {extDataState.productOptionCharges
            .sort((a, b) => a.materialDescription.localeCompare(b.materialDescription))
            .map((charge) => (
              <Select.Option key={charge.id} value={charge.id}>
                {charge.materialDescription}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="ACC(액세서리)" name="accIds">
        <Select loading={loading} mode="multiple">
          {extDataState.productAccs
            .filter(({status}) => status !== 'hidden')
            .sort((a, b) => a.materialDescription.localeCompare(b.materialDescription))
            .map((acc) => (
              <Select.Option key={acc.id} value={acc.id}>
                {acc.materialDescription}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="후크" name="hookIds">
        <Select loading={loading} mode="multiple">
          {extDataState.colors
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((color) => (
              <Select.Option key={color.id} value={color.id}>
                {color.name} ({color.code})
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="브랜드" name="brandId" rules={[{required: true, message: '브랜드를 선택해주세요'}]}>
        <Select loading={loading}>
          {extDataState.brands
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((brand) => (
              <Select.Option key={brand.id} value={brand.id}>
                {brand.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="마케팅" name="marketingPlatformId">
        <Select loading={loading}>
          {extDataState.marketingPlatforms
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((platform) => (
              <Select.Option key={platform.id} value={platform.id}>
                {platform.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="판매 제품" name="salesProductId">
        <Select loading={loading}>
          {extDataState.salesProducts
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((product) => (
              <Select.Option key={product.id} value={product.id}>
                {product.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="판매 제품 상세" name="salesProductDetailId">
        <Select loading={loading}>
          {extDataState.salesProductDetails
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((productDetail) => (
              <Select.Option key={productDetail.id} value={productDetail.id}>
                {productDetail.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="최종 분류 값" name="finalValueId">
        <Select loading={loading} allowClear>
          {extDataState.finalValues
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((finalVal) => (
              <Select.Option key={finalVal.id} value={finalVal.id}>
                {finalVal.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="ITE 옵션 값" name="iteOptionId">
        <Select loading={loading}>
          {extDataState.iteOptions
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((finalVal) => (
              <Select.Option key={finalVal.id} value={finalVal.id}>
                {finalVal.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="상태" name="status">
        <Radio.Group defaultValue="published">
          <Radio value="published">판매 중</Radio>
          <Radio value="discontinued">판매 중지</Radio>
          <Radio value="hidden">숨김</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )

  const excelAddType = (
    <>
      <Space align="end" style={{margin: '10px 0 10px auto'}}>
        <AntdUpload accept=".xls, .xlsx" onChange={handleUploadExcel} showUploadList={false}>
          <Button type="primary" shape="round">
            <FileExcelOutlined />
            엑셀 파일 업로드
          </Button>
        </AntdUpload>
        <Button shape="round" onClick={handleExcelReset}>
          초기화
        </Button>
      </Space>
      <Table rowKey="material" columns={columns} dataSource={excelData} style={{overflow: 'auto'}} />
    </>
  )

  return (
    <Wrapper>
      {type === 'add' && selectAddType}
      <StyledForm {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
        {addType === 'default' ? defaultAddType : excelAddType}
      </StyledForm>
    </Wrapper>
  )
}

const ProductModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '상품 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [addType, setAddType] = useState<'default' | 'excel'>('default')
  const [excelData, setExcelData] = useState<IProduct[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>(Array(21))
  const [keys, setKeys] = useState<string[]>([])

  const columns = defaultKeys.map(({title}, index) =>
    getColumnItem({selectedKeys, setSelectedKeys, setExcelData, index, title, isError: true})
  )

  columns.push({
    dataIndex: 'error',
    title: <>에러 메세지</>,
    render: (error) => <Typography.Text type="danger">{error}</Typography.Text>
  })

  function handleExcelReset() {
    setExcelData([])
    setKeys([])
  }

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchProducts, record?: IPatchProducts) {
    async function handleDefaultAddOrEdit() {
      try {
        const {id, ...rest} = values
        if (rest?.titleImage?.length) rest.titleImage = await uploadImage(rest.titleImage)
        if (!rest.status) rest.status = 'published'
        if (id) {
          Object.keys(rest).forEach((key) => {
            if (rest[key] === undefined) rest[key] = null
            else if (Array.isArray(rest[key]) && !rest[key].length) delete rest[key]
          })
          if (record) await patchProduct(id, {...rest})
        } else {
          await postProduct({...rest})
        }
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 409) Modal.error({content: '409: 해당 바코드는 이미 존재합니다.'})
          else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
    }

    async function handleExcelAdd() {
      try {
        excelData.map((dataItem) => {
          defaultKeys.forEach(({key}, index) => {
            const temp = dataItem[selectedKeys[index]]
            dataItem[key] = temp
          })
          return dataItem
        })

        const excelDataChunked = chunk(excelData, 50)
        for (const chunkedItem of excelDataChunked) {
          try {
            await postExcel({
              type: 'product',
              productData: chunkedItem.map((item) => {
                if (item.barcode && typeof item.barcode !== 'string') item.barcode = `${item.barcode}`
                if (item.materialDescription && typeof item.materialDescription !== 'string')
                  item.materialDescription = `${item.materialDescription}`
                if (item.material && typeof item.material !== 'string') item.material = `${item.material}`
                // item.brand =
                //   item.brand === 'Common Brand' ? item.brand : item.brand.split(' ')[item.brand.split(' ').length - 1]
                item.productFinalValue = `${item.productFinalValue}`
                item.salesProduct = `${item.salesProduct}`
                if (item.productFinalValue === 'undefined') delete item.productFinalValue
                if (item.salesProduct === 'undefined') delete item.salesProduct
                if (!item.marketingPlatform) delete item.marketingPlatform
                if (typeof item.productGrade === 'number') item.productGrade = `${item.productGrade}`
                return item
              })
            })
          } catch (e: any) {
            Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
          }
        }

        // handleExcelReset()
        // ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        // onAction(type, values)
      } catch (e: any) {}
    }

    async function handleDelete() {
      if (values.id) {
        await deleteProduct(values.id)
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      }
    }

    setLoading(true)
    if ((type === 'add' && addType === 'default') || type === 'edit') await handleDefaultAddOrEdit()
    else if (type === 'add' && addType === 'excel') await handleExcelAdd()
    else if (type === 'delete') await handleDelete()
    setLoading(false)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1000}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <>
              <AddOrEdit
                type={type}
                form={form}
                record={record}
                handleFinish={(values) => {
                  handleFinish(type, values, record)
                }}
                addType={addType}
                setAddType={setAddType}
                excelData={excelData}
                setExcelData={setExcelData}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                handleExcelReset={handleExcelReset}
                keys={keys}
                setKeys={setKeys}
              />
            </>
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 28px;
`

const StyledForm = styled(Form)`
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export default ProductModal
